import Navbar from "../../components/Navbar";
import SideBar from "../../components/SideBar";
import DisplayContainer from "../../components/DisplayContainer";

function UserStorage() {
    return (
        <>
            <Navbar />
            <div id="mainCont">
                <SideBar />
                <DisplayContainer />
            </div>
        </>
    );
}







export default UserStorage;
